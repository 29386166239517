/* eslint-disable no-param-reassign */
class ProductSelection {
  constructor(element) {
    const selectElement = element.querySelector('select.item-size-select');

    const itemInputElements = element.querySelectorAll('.item-input:not(:disabled)');
    const itemSizePickElement = element.querySelector('.item-name-pick');

    const itemSinglePrice = element.querySelector('.item-price-size');
    const itemFullPrice = element.querySelector('.item-price:not(.item-price-size)');

    const extrasElements = element.querySelectorAll('[data-extras]');
    const packagingElements = element.querySelectorAll('[data-packaging]');

    let variantName = '';
    let priceNet = 0;
    let priceSum = 0;
    let priceTotal = 0;
    let taxTotal = 0; // eslint-disable-line no-unused-vars

    function redirect(goto) {
      if (goto !== '') {
        window.location.href = goto;
      }
    }

    function updatePrice() {
      priceNet = 0;
      priceSum = 0;
      priceTotal = 0;
      taxTotal = 0;

      itemInputElements.forEach((inputElement) => {
        if (inputElement.dataset.priceNet !== undefined
          && inputElement.checked === true
          && inputElement.offsetParent !== null) {
          priceNet = parseFloat(inputElement.dataset.priceNet);
          taxTotal = parseFloat((priceNet / 100) * 19);

          priceTotal += priceNet;// + taxTotal;
          priceSum = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(priceTotal);

          itemSinglePrice.textContent = ` ${priceSum} `;
          itemFullPrice.textContent = ` ${priceSum} `;
        }
      });
    }

    updatePrice();

    if (extrasElements) {
      const firstExtra = extrasElements[0];

      extrasElements.forEach((extrasElement) => {
        if (extrasElement !== firstExtra) {
          extrasElement.style.display = 'none';
        }
      });
    }

    if (packagingElements) {
      const firstPackaging = packagingElements[0];

      packagingElements.forEach((packagingElement) => {
        if (packagingElement !== firstPackaging) {
          packagingElement.style.display = 'none';
        }
      });
    }

    if (selectElement) {
      selectElement.onchange = function () { // eslint-disable-line func-names
        const goto = this.value;
        selectElement.value = redirect(goto);
      };
    }

    itemInputElements.forEach((inputElement) => {
      if (inputElement.checked === true && inputElement.name === 'item-layout') {
        variantName = inputElement.value;
        itemSizePickElement.textContent = `${variantName}`;

        if (window.location.hash === '') {
          window.location.hash = `#${inputElement.value}`;
        }
      }

      inputElement.addEventListener('change', () => {
        if (inputElement === selectElement) {
          selectElement.onchange = function () { // eslint-disable-line func-names
            const goto = this.value;
            redirect(goto);
          };
        }

        if (inputElement.name === 'item-layout') {
          variantName = inputElement.value;
          window.location = `#${variantName}`;
          itemSizePickElement.textContent = `${variantName}`;

          if (extrasElements) {
            extrasElements.forEach((extrasElement) => {
              if (extrasElement.dataset.extras !== variantName) {
                extrasElement.style.display = 'none';
              } else {
                extrasElement.style.display = 'flex';
              }
            });
          }

          if (packagingElements) {
            packagingElements.forEach((packagingElement) => {
              if (packagingElement.dataset.packaging !== variantName) {
                packagingElement.style.display = 'none';
              } else {
                packagingElement.style.display = 'flex';
              }
            });
          }
        }

        updatePrice();
      });

      if (window.location.hash.substr(1) === inputElement.value) {
        inputElement.checked = true;
        itemSizePickElement.textContent = `${inputElement.value}`;

        updatePrice();
      }
    });
  }
}

export default ProductSelection;
