class SlideshowHero {
  constructor() {
    const slideshow = document.querySelector('header .slider_wrapper');
    this.slideshow = slideshow;

    const slide_wrapper = document.querySelector('header .slider_wrapper .wrapper > .inner');
    this.slide_wrapper = slide_wrapper;

    const slides = document.querySelectorAll('header .slider_wrapper .ce_slide');
    this.slides = slides;

    const slide_count = slides.length;
    this.slide_count = slide_count;

    const slider_button = document.querySelector('header .slider_wrapper .next_slide');
    this.slider_button = slider_button;

    // init Slider:
    let si = 1;

    if (slides) {
      Array.prototype.forEach.call(slides, (slide) => {
        if (si == 1) { slide.classList.add('slide_active'); } else if (si == 2) { slide.classList.add('slide_next'); } else { slide.classList.add('slide_upcoming'); }
        slide.setAttribute('data-index', si);
        si++;
      });
    }

    slider_button.addEventListener('click', (event) => {
      if (!slide_wrapper.classList.contains('intransition')) {
        event.preventDefault();
        this.next_slide(slide_count);
      }
    });

    slideshow.classList.add('is_ready');

    window.addEventListener('load', () => {});
  }

  next_slide(slide_count) {
    const { slideshow } = this;
    const { slide_wrapper } = this;

    const current_slide = slideshow.querySelector('.slide_active');
    const next_slide = slideshow.querySelector('.slide_next');
    const upcoming_slide = slideshow.querySelector('.slide_next').nextElementSibling;

    slide_wrapper.classList.add('intransition');

    if (current_slide.querySelector('video')) {
      current_slide.querySelector('video').pause();
    }
    if (next_slide.querySelector('video')) {
      next_slide.querySelector('video').play();
    }

    // animation
    if (upcoming_slide == null) {
      // loop to first slide
      current_slide.classList.remove('slide_active');
      current_slide.querySelector('.hyperlink > div > *').style.transform = 'translateX(-100vw)';
      next_slide.classList.remove('slide_next');
      next_slide.classList.add('slide_active');
      slideshow.querySelector('.ce_slide:first-of-type').classList.remove('slide_upcoming');
      slideshow.querySelector('.ce_slide:first-of-type').classList.add('slide_next');
    } else {
      // go to next slide
      current_slide.classList.remove('slide_active');
      current_slide.querySelector('.hyperlink > div > *').style.transform = 'translateX(-100vw)';
      next_slide.classList.remove('slide_next');
      next_slide.classList.add('slide_active');
      upcoming_slide.classList.remove('slide_upcoming');
      upcoming_slide.classList.add('slide_next');
    }

    this.upcoming_slides_callback(current_slide, slide_wrapper);
  }

  upcoming_slides_callback(current_slide, slide_wrapper) {
    setTimeout(() => {
      // loop
      current_slide.querySelector('.hyperlink > div > *').style.transform = null;
      current_slide.classList.add('notransition', 'slide_upcoming');
      current_slide.offsetHeight; // should trigger a 'repaint'
      current_slide.classList.remove('notransition');
      setTimeout(() => {
        slide_wrapper.classList.remove('intransition');
      }, 6);
      if (current_slide.querySelector('video')) {
        current_slide.querySelector('video').currentTime = 0;
      }
    }, 500);
  }
}

export default SlideshowHero;
