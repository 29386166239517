class MenuTrigger {
  constructor(parentElement) {
    // parentElement = #nav_main_container
    const logoContainerElement = document.querySelector('#logo_container');

    const menuTriggerElement = parentElement.querySelector('.menu-trigger');
    // const menuTriggerElementAriaStatus = menuTriggerElement.getAttribute('aria-expanded');
    // const breadcrumbsElement = parentElement.querySelector('.mod_breadcrumb');
    const stickyElements = parentElement.querySelectorAll('.stickyButtons > div');

    const buttonElement = parentElement.querySelector('button.sibling');
    const contactDropdownElement = document.querySelector('.inner_padding.contact.block');

    if (menuTriggerElement) {
      menuTriggerElement.addEventListener('click', () => {
        parentElement.classList.add('visible');
        logoContainerElement.classList.add('temp_moved');
        stickyElements.forEach((opened) => { opened.classList.add('temp_hidden'); });

        if (parentElement.classList.contains('visible')) {
          parentElement.addEventListener('mouseleave', () => {
            // sets a delay to mouseleave so that the menu
            // doesn't close right away on mouseleave
            const mouseLeaveDelay = setTimeout(() => {
              parentElement.classList.remove('visible');
              logoContainerElement.classList.remove('temp_moved');
              stickyElements.forEach((opened) => { opened.classList.remove('temp_hidden'); });
            }, 800);

            parentElement.addEventListener('mouseenter', () => { clearTimeout(mouseLeaveDelay); });

            if (buttonElement.classList.contains('opened')) {
              contactDropdownElement.addEventListener('mouseenter', () => { clearTimeout(mouseLeaveDelay); });
            }
          });
        }
      });
    }
  }
}

export default MenuTrigger;
