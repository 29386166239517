/* eslint-disable no-param-reassign */

class ContactTrigger {
  constructor(parentElement) {
    // parentElement = #nav_main_container
    const cssStringContainer = 'transform: translateY(0) rotate(0) scale(1); z-index: 1; box-shadow: 0px 0px 1px rgba(52,58,67,.75), 0px 0px 2px rgba(52,58,67,.05), 0px 1px 2px rgba(52,58,67,.04), 0px 1px 4px rgba(52,58,67,.04);';
    const cssStringContent = 'transform: translateY(0) rotate(0) scale(1); opacity: 1;';
    const clearCSS = null;

    const buttonElement = parentElement.querySelector('button.contact-button');
    const contactDropdownElement = document.querySelector('.inner_padding.contact.block');

    if (buttonElement) {
      buttonElement.addEventListener('click', () => {
        if (!buttonElement.classList.contains('opened')) {
          buttonElement.classList.add('opened');
          buttonElement.setAttribute('aria-expanded', 'true');

          // update main nav
          parentElement.querySelector('nav').classList.add('highlight_active');

          // update dropdown
          parentElement.querySelector('.dropdown .contact').style.cssText = cssStringContainer;
          parentElement.querySelector('.dropdown .contact > div').style.cssText = cssStringContent;

          contactDropdownElement.addEventListener('mouseleave', () => {
            const mouseLeaveDelay = setTimeout(() => {
              buttonElement.classList.remove('opened');
              buttonElement.setAttribute('aria-expanded', 'false');

              // update main nav
              parentElement.querySelector('nav').classList.remove('highlight_active');

              // update dropdown
              parentElement.querySelector('.dropdown .contact').style.cssText = clearCSS;
              parentElement.querySelector('.dropdown .contact > div').style.cssText = clearCSS;
            }, 800);

            contactDropdownElement.addEventListener('mouseenter', () => {
              clearTimeout(mouseLeaveDelay);
            });
          });
        } else {
          buttonElement.classList.remove('opened');
          buttonElement.setAttribute('aria-expanded', 'false');

          // update main nav
          parentElement.querySelector('nav').classList.remove('highlight_active');

          // update dropdown
          parentElement.querySelector('.dropdown .contact').style.cssText = clearCSS;
          parentElement.querySelector('.dropdown .contact > div').style.cssText = clearCSS;
        }
      });
    }
  }
}

export default ContactTrigger;
