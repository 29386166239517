class ScrollingEvents {
  constructor() {
    const winH = window.innerHeight;
    this.winH = winH;

    const scrolTop = 0;
    this.scrolTop = scrolTop;

    const scrollLast = 0;
    this.scrollLast = scrollLast;

    const navContainer = document.querySelector('#nav_main_container');
    this.navContainer = navContainer;

    if (document.querySelector('#wrapper .mod_breadcrumb')) {
      const breadcrumbPosT = document.querySelector('#wrapper .mod_breadcrumb').getBoundingClientRect().top;
      this.breadcrumbPosT = breadcrumbPosT;
    }

    if (!document.querySelector('body').classList.contains('suppress_navigation')) {
      const autoplay_videos = document.querySelector('#main').querySelectorAll('.video_container');
      this.autoplay_videos = autoplay_videos;
    }

    const navH = 180;
    this.navH = navH;

    const fixedNavH = 60; // look at CSS: $megamenu-fixed-height
    this.fixedNavH = fixedNavH;

    const logoH = 28; // look at CSS: $megamenu-logo-size
    this.logoH = logoH;

    const navColorChanger = document.querySelector('#nav_main_container > nav > span');
    this.navColorChanger = navColorChanger;

    document.addEventListener('scroll', () => this.draw(), { passive: true });

    window.addEventListener('resize', () => this.updateSizes(), { passive: true });
    this.updateSizes();

    /*
        if(document.querySelector("#scroll_play")){

            function handleLoadedData(event) {
                // Get the full video duration
                document.querySelector("#scroll_play").classList.add("vid_ready");
            }

            var scrollPlayElement = document.querySelector("#scroll_play")
            scrollPlayElement.addEventListener('loadeddata',handleLoadedData);

        }
        */

    window.addEventListener('load', () => {
      this.updateSizes();
      this.initialCheck();

      /*
            if(document.querySelector("#scroll_play").classList.contains("vid_ready") && !document.querySelector("#scroll_play").classList.contains("vid_played")){

                document.querySelector("#scroll_play").classList.add("vid_played");
                document.querySelector("#scroll_play").play()

            }
            */
    });
  }

  initialCheck() {
    const { scrollTop } = document.scrollingElement;
    // megamenu
    const { navH } = this;
    const { fixedNavH } = this;
    this.changeNavBGcolor(scrollTop, navH, fixedNavH);

    if (scrollTop >= 18) {
      document.querySelector('#logo_container').classList.add('fixed'); // fix Logo // css sticky solution is active!
      if (scrollTop >= 62) {
        document.querySelector('#logo_container').classList.add('inverted'); // change logo color
        if (scrollTop >= 120 - 24) {
          document.querySelector('#nav_main_container .mod_breadcrumb + div').classList.add('visible'); // show sticky button(s)
          if (scrollTop >= 120) {
            document.querySelector('#nav_main_container').classList.add('fixed'); // add fading to fixed nav
          }
        }
      }
    }
  }

  updateSizes() {
    if (document.querySelector('#wrapper .mod_breadcrumb')) {
      ScrollingEvents.breadcrumbPosT = document.querySelector('#wrapper .mod_breadcrumb').getBoundingClientRect().top;
    }
  }

  updatePositions() {
    // video
    const { scrollTop } = document.scrollingElement;
    ScrollingEvents.scrollTop = scrollTop;
  }

  changeNavBGcolor(scrollTop, navH, fixedNavH) {
    // ONLY ON HOMEPAGE
    if (!document.querySelector('body').classList.contains('suppress_navigation')) {
      let calculatedOpacity = (scrollTop - ((navH - fixedNavH) - (navH - fixedNavH))) / (navH - fixedNavH);
      if (calculatedOpacity < 0) { calculatedOpacity = 0; } else if (calculatedOpacity > 1) { calculatedOpacity = 1; }
      const { navColorChanger } = this;
      navColorChanger.style.opacity = calculatedOpacity;
    }
  }

  closeDropdowns() {
    const { navContainer } = this;
    // close dropdown
    if (navContainer.querySelector('.opened')) {
      Array.prototype.forEach.call(navContainer.querySelectorAll('.dropdown > div:not(.form_sent)'), (dropdown_container) => {
        if (dropdown_container.style.transform != 'translateY(0) rotate(0) scale(1)') {
          dropdown_container.style.transform = null;
          const dropdown_elements = dropdown_container.childNodes;
          for (let i = 0; i <= dropdown_elements.length - 1; i++) {
            if (dropdown_elements[i].tagName == 'DIV') {
              dropdown_elements[i].style.opacity = 0;
              dropdown_elements[i].style.transform = null;
            }
          }
        }
      }); // update dropdown
      navContainer.querySelector('nav').classList.remove('highlight_active'); // update link in main nav
      // update link in main nav
      if (navContainer.querySelector('.stickyButtons button.opened')) { // check if element exists in stickyNav
        Array.prototype.forEach.call(navContainer.querySelectorAll('button.opened'), (bttn) => { bttn.classList.remove('opened'); });
      } else { // else
        navContainer.querySelector('button.opened').classList.remove('opened');
      }
    }
    // hide opened lang-dropdown
    if (document.querySelector('.mod_changelanguage')) {
      document.querySelector('.mod_changelanguage').classList.remove('visible');
      document.querySelector('#nav_main_container').classList.remove('lang_active');
      document.querySelector('.mod_changelanguage .options').style.height = '0';
      setTimeout(() => {
        document.querySelector('.mod_changelanguage .options').hidden = true;
        document.querySelector('.mod_changelanguage .options').style.height = null;
      }, 200);
    }
  }

  scrollingBasic(scrollTop) {
    this.updatePositions();
    // hide open dropdowns
    this.closeDropdowns();
    // show sticky buttons
    setTimeout(() => {
      document.querySelectorAll('#nav_main_container .stickyButtons > .temp_hidden').forEach((visible) => {
        visible.classList.remove('temp_hidden');
      });
    }, 224);
    document.querySelector('#logo_container').classList.remove('temp_moved'); // reset moved Logo
    const { navH } = this;
    const { fixedNavH } = this;
    this.changeNavBGcolor(scrollTop, navH, fixedNavH); // change Color of Nav (level1)
    document.querySelector('#nav_main_container').classList.remove('visible'); // hide visible sticky nav
    // Megamenu END
  }

  scrollingDown(scrollTop) {
    if (document.querySelector('#wrapper .mod_breadcrumb')) {
      const { breadcrumbPosT } = this;
      if (!document.querySelector('#nav_main_container .mod_breadcrumb').classList.contains('visible')) {
        if (scrollTop >= breadcrumbPosT) { // fix breadcrumb
          document.querySelector('#nav_main_container .mod_breadcrumb').classList.add('visible');
        }
      }
    }
    if (!document.querySelector('#nav_main_container').classList.contains('fixed')) {
      if (scrollTop >= 18) {
        document.querySelector('#logo_container').classList.add('fixed'); // fix Logo // css sticky solution is active!
        if (scrollTop >= 62) {
          document.querySelector('#logo_container').classList.add('inverted'); // change logo color
          if (scrollTop >= 120 - 10) {
            document.querySelector('#nav_main_container .mod_breadcrumb + div').classList.add('visible'); // show sticky button(s)
            if (scrollTop >= 120) {
              document.querySelector('#nav_main_container').classList.add('fixed'); // add fading to fixed nav
            }
          }
        }
      }
    }
  }

  scrollingUp(scrollTop) {
    // ONLY ON HOMEPAGE
    if (!document.querySelector('body').classList.contains('suppress_navigation')) {
      const { breadcrumbPosT } = this;
      if (document.querySelector('#nav_main_container .mod_breadcrumb').classList.contains('visible')) {
        if (scrollTop <= breadcrumbPosT) { // unfix breadcrumb
          document.querySelector('#nav_main_container .mod_breadcrumb').classList.remove('visible');
        }
      }
      if (scrollTop <= 120) {
        document.querySelector('#nav_main_container').classList.remove('fixed'); // remove fading from nav
        if (scrollTop <= 120 - 10) {
          document.querySelector('#nav_main_container .mod_breadcrumb').classList.remove('visible'); // hide sticky button(s)
          document.querySelector('#nav_main_container .mod_breadcrumb + div').classList.remove('visible'); // hide sticky button(s)
          if (scrollTop <= 62) {
            document.querySelector('#logo_container').classList.remove('inverted'); // change logo color
            if (scrollTop <= 18) {
              document.querySelector('#logo_container').classList.remove('fixed'); // unfix Logo // css sticky solution is active!
            }
          }
        }
      }
    }
  }

  draw() {
    //
    const { scrollTop } = document.scrollingElement;
    this.scrollingBasic(scrollTop);
    if (scrollTop > ScrollingEvents.scrollLast) { this.scrollingDown(scrollTop); } else if (scrollTop < ScrollingEvents.scrollLast) { this.scrollingUp(scrollTop); }
    ScrollingEvents.scrollLast = scrollTop;
  }
}

export default ScrollingEvents;
