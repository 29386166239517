/* eslint-disable no-new */

// import '@babel/polyfill/';
import "core-js/stable";
import 'airbnb-browser-shims/browser-only';

// core
import ViewportHeightFix from './components/ViewportHeightFix'; // 100vh fix for smartphones
import ScrollingEvents from './components/ScrollingEvents'; // scrolling

// menu
import ContactTrigger from './components/ContactTrigger';
import MenuTrigger from './components/MenuTrigger'; // mobile screens
import MenuTriggerMobile from './components/MenuTriggerMobile'; // big/regular screens
import ScrollTop from './components/ScrollTop'; // big/regular screens

// import MicrositeNavigation from './components/MicrositeNavigation'; // big/regular screens

// other
import SlideshowHero from './components/SlideshowHero'; // Home-Slideshow

// Kirby + Merx
import ProductSelection from './merx/components/productSelection';

document.addEventListener('DOMContentLoaded', () => {
  new ViewportHeightFix(); // 100vh fix for smartphones

  if (!document.querySelector('body').classList.contains('ie') && !document.querySelector('#simple_nav')) {
    new ScrollingEvents(); // scrolling
  }

  const docBody = document.querySelector('body');
  const navColorSwitcher = document.querySelector('.color-changer');
  if (!docBody.classList.contains('suppress_navigation')) {
    navColorSwitcher.style.opacity = 0;
  }

  // menu triggers
  if (window.matchMedia('screen and (max-width: 1100px)').matches) {
    const parentElement = document.querySelector('#logo_container');
    if (parentElement) {
      new MenuTriggerMobile(parentElement); // mobile navigation
    }
  } else {
    const parentElement = document.querySelector('#nav_main_container');
    if (parentElement) {
      new MenuTrigger(parentElement);
      new ContactTrigger(parentElement);
    }
  }

  // product selection
  const productElement = document.querySelector('.product-page:not(.product-page.variant-page)');
  if (productElement) {
    new ProductSelection(productElement);
  }

  // home slideshow
  if (document.querySelector('header .slider_wrapper')) {
    new SlideshowHero();
  }

  // scrollTop buttons
  const headerScrollTop = document.querySelector('#nav_main_container .h-scroll');
  const footerScrollTop = document.querySelector('#footer .f-scroll');
  if (headerScrollTop || footerScrollTop) {
    new ScrollTop(headerScrollTop, footerScrollTop);
  }
});
