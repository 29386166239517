class MenuTriggerMobile {
  constructor(parentElement) {
    // parentElement = #logo_container
    let navContainer = document.querySelector('#nav_main_container');
    const menuTriggerElement = parentElement.querySelector('.menu-trigger-mobile');
    // const menuTriggerElementAriaStatus = menuTriggerElement.getAttribute('aria-expanded');

    if (!navContainer) {
      navContainer = document.querySelector('#simple_nav .mod_articlelist');
    }

    menuTriggerElement.addEventListener('click', () => {
      if (!navContainer.classList.contains('visible')) {
        navContainer.classList.add('visible');
        menuTriggerElement.classList.add('nav_vis');
        menuTriggerElement.setAttribute('aria-expanded', 'true');
        document.querySelector('body').classList.add('preventscrolling');
      } else {
        navContainer.classList.remove('visible');
        menuTriggerElement.classList.remove('nav_vis');
        menuTriggerElement.setAttribute('aria-expanded', 'false');
        document.querySelector('body').classList.remove('preventscrolling');
      }
    });
  }
}

export default MenuTriggerMobile;
