class ScrollTop {
  constructor(headerScrollTop, footerScrollTop) {
    function scrollTop() {
      preventDefault();

      document.scrollingElement.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (headerScrollTop) {
      headerScrollTop.addEventListener('click', scrollTop);
    }

    if (footerScrollTop) {
      footerScrollTop.addEventListener('click', scrollTop);
    }
  }
}

export default ScrollTop;
